try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {
    console.log(e);
}

require('jquery-ui/ui/core.js');
require('jquery-ui/ui/widget.js');
require('jquery-ui/ui/widgets/draggable.js');
require('jquery-ui/ui/widgets/droppable.js');
require('jquery-ui/ui/widgets/selectable.js');
require('jquery-ui/ui/widgets/sortable.js');
require('jquery-ui/ui/widgets/resizable.js');
require('jquery-ui/ui/widgets/datepicker.js');
require('jquery-ui/ui/widgets/slider.js');
require('jquery-ui/ui/widgets/dialog.js');