window._ = require('lodash');

window.axios        = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.moment       = require('moment');
window.Cookies      = require('js-cookie');
window.ClipboardJS  = require('clipboard');
window.JSGantt      = require('jsgantt-improved');
window.TimeChart    = require('timechart/dist/timechart.min.js');
window.printThis    = require('print-this');
window.html2canvas  = require('html2canvas');

require('summernote/dist/summernote-bs4.min.js');

require('bootstrap-datepicker');
require('bootstrap-daterangepicker');
require('bootstrap-colorpicker');

require('admin-lte/build/js/AdminLTE.js');
require('admin-lte/plugins/inputmask/jquery.inputmask.min.js');
require('admin-lte/plugins/select2/js/select2.full.min.js');
require('admin-lte/plugins/datatables/jquery.dataTables.min.js');
require('admin-lte/plugins/datatables-buttons/js/buttons.bootstrap4.min.js');
require('admin-lte/plugins/datatables-bs4/js/dataTables.bootstrap4.min.js');
require('admin-lte/plugins/datatables-buttons/js/buttons.colVis.min.js');
require('admin-lte/plugins/datatables-buttons/js/buttons.html5.min.js');
require('admin-lte/plugins/datatables-buttons/js/buttons.print.min.js');
require('admin-lte/plugins/datatables-responsive/js/responsive.bootstrap4.min.js');

require('admin-lte/plugins/select2/js/select2.full.min.js');
require('admin-lte/plugins/bootstrap/js/bootstrap.bundle');
